import { HTTPClient } from "@/utils/request.hub";

export default {
  sellers: ({ params }) =>
    HTTPClient.get(`/Zoho/Vendedores/${params.projectId}`),
  pf: ({ payload }) => HTTPClient.get(`/cpf/${payload.cpf}`),
  pj: ({ payload }) => HTTPClient.get(`/cnpj/${payload.cnpj}`),
  buscarCep: ({ payload }) => HTTPClient.get(`/cep/${payload.cep}`),
  Viabilidade: ({ payload }) =>
    HTTPClient.get(`/Viabilidade/Oi/${payload.cep}/${payload.numero}`),
};
