<script>
import IconBase from "@components/icons/_icon-base.vue";
import IconLogo from "@components/icons/_icon-logo.vue";
import MBreadcrumb from "@components/molecules/m-breadcrumb.vue";

export default {
  name: "OHeader",
  components: {
    IconBase,
    IconLogo,
    MBreadcrumb,
  },
  props: {
    noBreadcrumb: Boolean,
  },
};
</script>

<template lang="pug">
  header( class="flex justify-between items-center px-4")
    //- IconBase(:size="21.425" class="text-primary")
    //-   IconLogo
    div(class="my-4")
      img(src="https://automatuslab.blob.core.windows.net/macro/VIVO/CHAT-BOT/CONTROLE%20B2B/logo.png" class="h-12")
    MBreadcrumb(v-if="!noBreadcrumb")  
</template>
