import { version } from "~/package.json";
import isMobileDevice from "~/src/utils/is-mobile-device";
import criptografiaSha256 from "~/src/utils/criptografia-sha256";
import stringSanitize from "~/src/utils/string-sanitize";
import { getRouteNameParsed } from "@utils/getRouteNameParsed";

const salt = process.env.VUE_APP_SALT;

function encryptionWithShaAndSalt(value, shouldSanitize = false) {
  if (shouldSanitize) {
    value = stringSanitize(value);
  }
  return criptografiaSha256(value + salt);
}

const Types = {
  CLEAR_PERSONAL_DATA: "CLEAR_PERSONAL_DATA",
  SET_ORDER_ID: "SET_ORDER_ID",
  SET_ORDER_PERSONAL_DATA: "SET_ORDER_PERSONAL_DATA",
  SET_ADDRESS: "SET_ADDRESS",
  SET_STATUS_ADDRESS: "SET_STATUS_ADDRESS",
  SET_EXPIRATION_DAY: "SET_EXPIRATION_DAY",
  SET_BILL_METHOD: "SET_BILL_METHOD",
  SET_BILL_METHOD__DEBITO: "SET_BILL_METHOD__DEBITO",
  SET_BILL_METHOD__BOLETO: "SET_BILL_METHOD__BOLETO",
  SET_MODALITY: "SET_MODALITY",
  SET_PRODUCT: "SET_PRODUCT",
  SET_ACTIVATED: "SET_ACTIVATED",
  SET_CONSULT_STATUS: "SET_CONSULT_STATUS",
  SET_CONFIG_BILL_INFO: "SET_CONFIG_BILL_INFO",
  SET_ID_SESSION_OFDB: "SET_ID_SESSION_OFDB",
  UPDATE_LINES: "UPDATE_LINES",
  UPDATE_QTY_LINES: "UPDATE_QTY_LINES",
};

export const state = {
  identificadorPedido: "",
  lead: {},
  idSessionOFDB: null,
  endereco: {
    cep: "20550140",
    // logradouro: "Rua Visconde de Itamarati",
    numero: "2",
    bairro: "Maracanã",
    uf: "RJ",
    localidade: "Rio de Janeiro",
  },
  diaVencimento: "07",
  formaPagamento: "Débito automático",
  modalidade: "Migracao",
  produto: {},
  activated: false,
  consultStatus: false,
  linhas: [],
  qtdePortabilidade: 0,
  qtdeNovaLinha: 0,
  qtdeOffice: 1,
};

export const getters = {
  getPageview: (state, getter, rootState, rootGetters) => {
    const basePagePath = "checkout-shinobi";
    const url = new URL(window.location.href);
    const pathName = url.pathname;
    const routeName = getRouteNameParsed(pathName, true);
    let pagePath = `${basePagePath}/${routeName}`;
    if (routeName === "sucesso")
      pagePath = `${basePagePath}/${routeName}/${state.identificadorPedido}`;

    const planType = rootGetters["catalog/getTipoPlano"];
    let businessFront = basePagePath + (planType ? `-${planType}` : "-fixa");

    return {
      page: {
        pagePath,
        businessFront,
        area: "b2b",
        segment: "ecommerce",
        environment: isMobileDevice() ? "mobile" : "web",
        platform: `shinobi:${version}`,
      },
      user: {
        id: undefined,
        cpf: state.lead.cpf
          ? encryptionWithShaAndSalt(state.lead.cpf, true)
          : undefined,
        cnpj: state.lead.cnpj
          ? encryptionWithShaAndSalt(state.lead.cnpj, true)
          : undefined,
        email: state.lead.email
          ? encryptionWithShaAndSalt(state.lead.email)
          : undefined,
        msisdn: state.lead.telefone
          ? encryptionWithShaAndSalt(state.lead.telefone, true)
          : undefined,
        address:
          routeName === "consulta-cobertura"
            ? {
                city: undefined,
                state: undefined,
                postalCode: undefined,
                country: undefined,
              }
            : {
                city: state.endereco.localidade
                  ? stringSanitize(state.endereco.localidade)
                  : undefined,
                state: state.endereco.uf
                  ? stringSanitize(state.endereco.uf)
                  : undefined,
                postalCode: state.endereco.cep
                  ? encryptionWithShaAndSalt(state.endereco.cep, true)
                  : undefined,
                country: "BRA",
              },
      },
    };
  },
  getOrderId: state => state.identificadorPedido,
  getOrder: state => ({ ...state }),
  getAddress: state => state.endereco,
  getLead: state => state.lead,
  getLeadTelefone: state => state.lead.telefone || "",
  getLeadCpf: state => state.lead.cpf || "",
  getLeadCep: state => state.lead.cep || "",
  getLeadName: state => state.lead.nome || "",
  getLeadNameMother: state => state.lead.nomeMae || "",
  getVencimento: state => state.diaVencimento,
  getBillMethod: state => state.formaPagamento,
  getModality: state => state.lead.modalidade,
  getInfoParabens: state => [
    { titulo: "Nome", useMask: true, detalhe: state.lead.nome },
    { titulo: "CNPJ", useMask: true, detalhe: state.lead.cnpj },
    {
      titulo: "Sua Fatura",
      useMask: false,
      detalhe: `Vencimento no dia ${state.lead.fatura.diaVencimento} de cada mês`,
    },
  ],
  getDiasVencimento: state => state.billConfig.diaVencimento,
  getListBank: state => {
    const listBank = state.billConfig.bancosDA;

    return listBank.map(bank => ({
      value: bank.codigo,
      text: bank.nome,
    }));
  },
  isActvated: state => state.activated,
  isConsultStatus: state => state.consultStatus,
  getIdSessionOFDB: state => state.idSessionOFDB,
  getLines: state => state.linhas,
  getQtdePortabilidade: state => state.qtdePortabilidade,
  getQtdeNovaLinha: state => state.qtdeNovaLinha,
  getQtdeOffice: state => state.qtdeOffice,
  getPlanToFooter: (state, _, __, rootGetters) => {
    const selectedPlan = rootGetters["catalog/getSelectedPlan"];

    if (selectedPlan.sku && selectedPlan.valores_plano.valor_com_desconto) {
      return {
        dados: selectedPlan.info_plano.dados,
        sku: selectedPlan.sku,
        price: selectedPlan.valores_plano.valor_com_desconto,
      };
    }
    return undefined;
  },
  getPlanToEnchanced: (state, _, __, rootGetters) => {
    const selectedPlan = rootGetters["catalog/getSelectedPlan"];
    // console.log("selectedPlan", selectedPlan);
    if (selectedPlan) {
      return {
        sku: selectedPlan.sku,
        price: selectedPlan.valores_plano.valor_com_desconto,
        dados: selectedPlan.info_plano.dados,
        dadosSubtitle: selectedPlan.nome,
      };
    }
    return undefined;
  },
  getSumLinesPrice: (state, _, __, rootGetters) => {
    const telPrices = rootGetters["catalog/getSelectedPlan"].valores_plano
      .valor_do_fixo || [50, 20, 20, 20];
    const planPrice =
      rootGetters["catalog/getSelectedPlan"].valores_plano.valor_com_desconto ||
      0;
    const totalLinhas = state.qtdeNovaLinha + state.qtdePortabilidade;

    if (rootGetters["catalog/getTipoPlano"] !== "total") {
      const planPriceWithLines = [...Array(totalLinhas).keys()].reduce(
        (acc, _, index) => acc + telPrices[index],
        planPrice
      );

      if (rootGetters["catalog/getOffice"]) {
        const unitValue = rootGetters["catalog/getOffice"].valorUnitario;
        const aboveQty =
          state.qtdeOffice <= 0 ? state.qtdeOffice : state.qtdeOffice - 1;
        return planPriceWithLines + aboveQty * unitValue;
      }

      return planPriceWithLines;
    }

    return planPrice;
  },
};

export const mutations = {
  [Types.CLEAR_PERSONAL_DATA]: state => {
    state.lead = {};
    state.endereco = {};
    state.statusEndereco = false;
    state.qtdeNovaLinha = 0;
    state.qtdePortabilidade = 0;
  },
  [Types.SET_ORDER_ID]: (state, orderId) => {
    state.identificadorPedido = orderId;
  },
  [Types.SET_ORDER_PERSONAL_DATA]: (state, lead) => {
    state.lead = { ...state.lead, ...lead };
  },
  [Types.SET_ADDRESS]: (state, address) => {
    state.endereco = address;
  },
  [Types.SET_EXPIRATION_DAY]: (state, diaVencimento) => {
    state.diaVencimento = diaVencimento;
  },
  [Types.SET_BILL_METHOD__DEBITO]: state => {
    state.formaPagamento = "Débito automático";
  },
  [Types.SET_BILL_METHOD__BOLETO]: state => {
    state.formaPagamento = "Boleto digital";
  },
  [Types.SET_MODALITY]: (state, modalidade) => {
    state.modalidade = modalidade;
  },
  [Types.SET_PRODUCT]: (state, product) => {
    state.produto = { ...product };
  },
  [Types.SET_ACTIVATED]: (state, activated) => {
    state.activated = activated;
  },
  [Types.SET_CONSULT_STATUS]: (state, activated) => {
    state.consultStatus = activated;
  },
  [Types.SET_CONFIG_BILL_INFO]: (state, configs) => {
    state.billConfig = configs;
  },
  [Types.SET_ID_SESSION_OFDB]: (state, idOFDB) => {
    state.idSessionOFDB = idOFDB;
  },
  [Types.UPDATE_LINES]: (state, lines) => {
    state.linhas = lines;
  },
  [Types.UPDATE_QTY_LINES]: (
    state,
    { qtdePortabilidade, qtdeNovaLinha, qtdeOffice }
  ) => {
    state.qtdePortabilidade = qtdePortabilidade;
    state.qtdeNovaLinha = qtdeNovaLinha;
    state.qtdeOffice = qtdeOffice;
  },
};

export const actions = {
  clearData: ({ commit }) => commit(Types.CLEAR_PERSONAL_DATA),
  setOrderId: ({ commit }, orderId) => commit(Types.SET_ORDER_ID, orderId),
  setPersonalData: ({ commit, rootGetters }, lead) => {
    let {
      dadosAdicionais: { cidade, uf, ddd },
    } = rootGetters.getQuery;
    commit(Types.SET_ORDER_PERSONAL_DATA, { ...lead, cidade, uf, ddd });
  },
  setRetomadaName: ({ commit }, nome) => {
    commit(Types.SET_ORDER_PERSONAL_DATA, { nome });
  },
  setAddress: ({ commit }, endereco) => {
    commit(Types.SET_ADDRESS, endereco);
  },
  setExpirationDay: ({ commit }, diaVencimento) => {
    commit(Types.SET_EXPIRATION_DAY, diaVencimento);
  },
  setBillMethod: ({ commit }, isDebit) => {
    if (isDebit) commit(Types.SET_BILL_METHOD__DEBITO);
    else commit(Types.SET_BILL_METHOD__BOLETO);
  },
  setModality: ({ commit }, modalidade) =>
    commit(Types.SET_MODALITY, modalidade),
  setProduct: ({ commit }, order) => {
    commit(Types.SET_PRODUCT, order);
  },
  setActivated: ({ commit }, payload) => {
    commit(Types.SET_ACTIVATED, payload);
  },
  setConsulStatus: ({ commit }, payload) => {
    commit(Types.SET_CONSULT_STATUS, payload);
  },
  setBillConfig: ({ commit }, payload) => {
    commit(Types.SET_CONFIG_BILL_INFO, payload);
  },
  setIdSessionOFDB: ({ commit }, payload) => {
    commit(Types.SET_ID_SESSION_OFDB, payload);
  },
  updateLines: ({ commit }, lines) => {
    commit(Types.UPDATE_LINES, lines);
  },
  updateQtyLines: (
    { commit },
    { qtdePortabilidade, qtdeNovaLinha, qtdeOffice }
  ) => {
    commit(Types.UPDATE_QTY_LINES, {
      qtdePortabilidade: Number(qtdePortabilidade),
      qtdeNovaLinha: Number(qtdeNovaLinha),
      qtdeOffice: Number(qtdeOffice),
    });
  },
};
