<script>
import { mapActions } from "vuex";
import IconBase from "../components/icons/_icon-base.vue";
import IconVivizinho from "../components/icons/_icon-vivizinho.vue";

export default {
  name: "Checkout",
  components: {
    IconBase,
    IconVivizinho,
  },

  mounted() {
    const isSellerMode = !!this.$route.query.sellerMode;
    if (!isSellerMode) this.clearSellers();
    this.changeSellerMode(isSellerMode);
    this.resetModal();
    this.clearOrder("");
    this.clearCatalog();
    const utm_referrer =
      window.document.referrer !== "" ? window.document.referrer : "direto";
    const company = this.$route.query.company || "revendavivo";
    const vitrine = this.$route.query.vitrine || "DEFAULT";
    const queries = { ...this.$route.query, utm_referrer, company, vitrine };
    const queriesWithoutCompany = { ...this.$route.query, utm_referrer };
    this.setQueriesParams(queries);
    this.setSelectedPlan(queries.sku);
    this.routerOrchestrator(queriesWithoutCompany);

    // this.$gtm.sendEcommerceEvent("teste", {});
  },
  computed: {
    tipoPlanoParam() {
      return this.$store.getters["catalog/getTipoPlano"];
    },
  },
  methods: {
    ...mapActions({
      resetModal: "modal/resetModal",
      clearOrder: "order/clearData",
      clearCatalog: "catalog/clearData",
      setSelectedPlan: "catalog/setSelectedPlan",
      setQueriesParams: "setQueriesParams",
      clearSellers: "catalog/clearSellers",
      changeSellerMode: "changeSellerMode",
    }),

    async routerOrchestrator(queries) {
      if (queries.redirect)
        return await this.$router.push(
          `/${this.tipoPlanoParam}${queries.redirect.toLowerCase()}`
        );

      // return this.$router.push("/consulta-cobertura");
      return this.$router.push({ path: "/consulta-cobertura", query: queries });
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <icon-base :size="16"><icon-vivizinho /></icon-base>
  </div>
</template>
