<script>
import IconBase from "@components/icons/_icon-base.vue";
import IconVivizinho from "@components/icons/_icon-vivizinho.vue";

export default {
  name: "ALoading",
  components: {
    IconBase,
    IconVivizinho,
  },
  data: () => ({
    time: 0,
  }),
  mounted() {
    this.time = 0;
    setInterval(() => {
      this.time++;
    }, 1000);
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 bg-dark-200 bg-opacity-75 text-light flex flex-col items-center justify-center z-50"
  >
    <icon-base :size="16"><icon-vivizinho /></icon-base>
    <transition>
      <p class="text-xl font-normal text-light mt-12" v-if="time <= 1">
        Carregando
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 2">
        Carregando.
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 3">
        Carregando..
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 4">
        Carregando...
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 5">
        Carregando....
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 6">
        Só mais um instante
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 7">
        Só mais um instante.
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 8">
        Só mais um instante..
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time === 9">
        Só mais um instante...
      </p>
      <p class="text-xl font-normal text-light mt-12" v-if="time > 9">
        Estamos quase lá...
      </p>
    </transition>
  </div>
</template>
